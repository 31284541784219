<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card :loading="isLoading">
        <v-card-title>
          <span class="headtitle-email">{{$_strings.shippingProblem.headerDoneProblem}}</span>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation style="padding: 8px;">
          <v-container>
            <v-subheader style="padding: 0px">{{$_strings.shippingProblem.headerNote}}</v-subheader>
            <v-textarea
              v-model="form.solutionNote"
              no-resize
              clearable
              outlined
              rows="5"
              :counter="255"
              :rules="[(v) => rulesNotes(v, true, 255, 'Catatan')]"
            ></v-textarea>
            <v-card-actions style="padding: 5px 0px 0px 0px;">
              <v-btn
                outlined
                medium
                class=""
                @click="dialog = false"
              >
                {{$_strings.shippingProblem.buttonBatal}}
              </v-btn>
              <v-btn
                outlined
                medium
                class="mr-2 ml-auto"
                @click="showDialogConfirmation('cancelDelivery')"
                style="background: #d84d46;"
                color="white"
              >
                {{$_strings.shippingProblem.buttonBatalAssign}}
              </v-btn>
              <v-btn
                depressed
                medium
                color="primary"
                class="white--text"
                @click="showDialogConfirmation('continueShipping')"
              >
                {{$_strings.shippingProblem.buttonNextAssign}}
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <DialogConfirmation
      ref="dialogConfirmation"
      @continueShipping="continueShipping"
      @cancelDelivery="cancelDelivery"
    />
  </v-row>
</template>

<script>
import DialogConfirmation from './Confirmation.vue';
import { rulesNotes } from '../../../helper/commonHelpers';

const date = new Date();

export default {
  components: {
    DialogConfirmation,
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.form.reset();
        this.form = {
          solution: '',
          solutionNote: '',
          statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
          encodedFile: [],
        };
      }
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      item: {},
      form: {
        solution: '',
        solutionNote: '',
        statusDatetimeTz: date.toString().split('+')[1].substr(1, 1),
        encodedFile: [],
      },
      rules: {
        solutionNote: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Catatan'),
        ],
      },
      // items: 0,
      accidentId: 0,
    };
  },
  methods: {
    rulesNotes,
    showDialogConfirmation(action) {
      if (!this.$refs.form.validate()) return;
      let msg = '';
      if (action === 'continueShipping') msg = 'Apakah Anda Yakin Untuk Melajutkan Pengiriman ?';
      if (action === 'cancelDelivery') msg = 'Apakah Anda Yakin Membatalkan Pengiriman ?';
      this.$refs.dialogConfirmation.title = `<div class="font-weight-bold"><p class="text-center mb-0">${msg}</p></div>`;
      this.$refs.dialogConfirmation.action = action;
      this.$refs.dialogConfirmation.dialog = true;
    },
    continueShipping() {
      if (!this.$refs.form.validate()) return;
      const { cargoAccidentId } = this.item;
      this.isLoading = true;
      this.form.solution = 'LANJUTKAN_PENGIRIMAN';
      this.$_services.shippingProblem.shippingProblemDone(cargoAccidentId, this.form)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.shippingProblem.alertDoneProblem);
          this.dialog = false;
          this.$emit('fetchShippingProblem');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancelDelivery() {
      if (!this.$refs.form.validate()) return;
      const { cargoAccidentId } = this.item;
      this.isLoading = true;
      this.form.solution = 'BATALKAN';
      this.$_services.shippingProblem.shippingProblemDone(cargoAccidentId, this.form)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.shippingProblem.alertDoneProblem);
          this.dialog = false;
          this.$emit('fetchShippingProblem');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.headtitle-email{
  font-size: 16px;
  font-weight: bold;
}
</style>
