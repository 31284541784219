<template>
  <v-sheet elevation="1">
    <v-row
      dense
      align="center"
      class="pa-5"
      justify-md="space-between"
    >
      <v-col cols="auto">
        <span style="font-weight: bold">{{orderBy}} </span>
      </v-col>
      <v-col
        cols="12"
        sm="10"
        md="6"
        class="ml-md-auto"
      >
        <v-row>
          <v-col cols="12" sm="4" md="5">
            <v-text-field
              v-model="accidentReason"
              dense
              outlined
              class="caption"
              item-text="reason"
              item-value="reason"
              placeholder="Filter Masalah"
              clearable
              hide-details
              @keyup.native.enter="handleReason"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="handleReason">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <v-text-field
              v-model="search"
              dense
              hide-details
              outlined
              class="caption"
              @keyup.native.enter="setSearch"
              :placeholder="$_strings.shippingProblem.searchNo"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setSearch">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>

export default {
  name: 'filter-shipping-problem',
  props: {
    setFilter: Function,
    orderBy: String,
  },
  data() {
    return {
      search: this.$route.query.search || '',
      accidentReason: this.$route.query.accidentReason || '',
    };
  },
  methods: {
    handleReason() {
      this.setFilter('accidentReason', this.accidentReason);
    },
    setSearch() {
      this.setFilter('search', this.search);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
  font-size: 10px !important;
  color: red !important;
}
</style>
