<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headtitle-email">{{$_strings.shippingProblem.headerAssign}}</span>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                :disabled="isLoading"
                @click="setDialogAssignProblem('dialogAssignProblem')"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-row class="ml-3">
          <v-col>
            <v-icon color="yellow accent-4">
              mdi-alert-circle
            </v-icon>
            <span class="ml-3">{{$_strings.shippingProblem.headerDoneProblem}}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          outlined
          medium
          class="mr-2 ml-auto"
          :disabled="isLoading"
          @click="setDialogAssignProblem('dialogAssignProblem')"
        >
          {{$_strings.shippingProblem.cancel}}
        </v-btn>
        <v-btn
          depressed
          medium
          color="primary"
          class="white--text"
          @click="submit()"
          :loading="isLoading"
        >
          {{$_strings.shippingProblem.yes}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    dialogAssignProblem: Boolean,
    setDialogAssignProblem: Function,
    setAssignProblem: Object,
  },
  watch: {
    dialogAssignProblem() {
      this.dialog = this.dialogAssignProblem;
    },
    setAssignProblem() {
      this.items = this.setAssignProblem;
      this.accidentId = this.items.cargoAccidentId;
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: this.dialogAssignProblem,
      items: [],
      accidentId: '',
    };
  },
  methods: {
    async accidentExists() {
      try {
        const res = await this.$_services.shippingProblem.accidentExists();
        this.$store.dispatch('user/setAccidentExists', res.data);
      } catch (e) {
        console.error(e);
      }
    },
    submit() {
      this.isLoading = true;
      this.$_services.shippingProblem.shippingProblemProses(this.accidentId)
        .then(() => {
          this.$dialog.notify.success('Masalah  berhasil di proses');
          this.$emit('fetchShippingProblem');
          this.accidentExists();
          this.setDialogAssignProblem('dialogAssignProblem');
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
.headtitle-email{
  font-size: 16px;
  font-weight: bold;
}
</style>
